import keymirror from 'keymirror'

export const ASSETS_VIEWS = keymirror({
  site: null,
  well: null,
  pipeline: null,
})

export const ASSETS_TAB_ROUTES = keymirror({
  sites: null,
  wells: null,
  pipelines: null,
})

export const ASSETS_VIEWS_TABS = [
  {
    value: ASSETS_VIEWS.site,
    label: 'Sites',
    route: ASSETS_TAB_ROUTES.sites,
  },
  {
    value: ASSETS_VIEWS.well,
    label: 'Wells',
    route: ASSETS_TAB_ROUTES.wells,
  },
  {
    value: ASSETS_VIEWS.pipeline,
    label: 'Pipelines',
    route: ASSETS_TAB_ROUTES.pipelines,
  },
]
